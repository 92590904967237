<template>
  <div class="list-item" @click.stop="$emit('toDetail')">
    <div class="item-img relative">
      <div class="labelBox flex align-center" v-if="label">
        <div class="text mlr10">{{ goods.tag[0] }}</div>
      </div>
      <div>
        <el-carousel
          height="280px"
          ref="carouselRef"
          indicator-position="none"
          arrow="never"
          :autoplay="autoplay"
          :interval="1000"
          @mouseenter.native="handleMouseOver"
          @mouseleave.native="handleMouseLever"
        >
          <el-carousel-item
            v-for="(item, index) in goods.coverUrl"
            :key="index"
          >
            <img :src="item.url | filterPicture" alt="" v-if="item.url" />
            <img :src="item | filterPicture" alt="" v-else />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="item-text">
      <div class="item-name">
        <span class="goods-sn">{{ goods.sn }}</span>
        <span>{{ goods.name }}</span>
      </div>
      <el-rate
        v-model="goods.starRating"
        disabled
        :colors="colors"
      />
      <div class="buyLimit_wrapper ">
        <label v-show="goods.purchaseLimit !== 0">Límite de compra: {{ goods.purchaseLimit }}</label>
      </div>
      <template>
        <div v-if="discount == 1" class="discount flex flex-center mt5">
          <span class="text">{{ goods.promotionDiscount }}% de descuento</span>
        </div>
        <div v-else>
          <div
            class="vip"
            v-if="goods.deductionPrice && goods.participationDiscount"
          >
            <img src="@/assets/home/vip.png" alt="" />
            <span>COP {{ goods.deductionPrice | filterPrice }}</span>
          </div>
        </div>
      </template>
      <div class="item-price">
        <div v-if="discount == 1" class="discountPrice">
          <span>COP {{ goods.promotionPrice | filterPrice }}</span>
          <span class="oldPrice ml10">COP {{ goods.price | filterPrice }}</span>
        </div>
        <div v-else>
          <span>COP {{ goods.price | filterPrice }}</span>
        </div>
      </div>
      <div class="product-icon_wrapper">
        <div
          class="item-icon icon-collect"
          @click.stop="$emit('handel-collect')"
        >
          <img
            src="@/assets/goods/collect-active.png"
            alt=""
            v-if="goods.collected"
          />
          <img src="@/assets/goods/collect.png" alt="" v-else />
        </div>
        <div class="item-icon" @click.stop="addCart">
          <img src="@/assets/home/cart.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ts-nocheck

import { json } from 'body-parser'

export default {
  props: ['collect', 'goods'],

  data() {
    return {
      discount: false,
      label: false,
      colors: ['#FCBBBB', '#FCBBBB', '#FCBBBB'],
      autoplay: false
    }
  },
  watch: {
    goods(val) {
      console.log('val---', val)
    }
  },
  created() {
    if (this.goods.hasPromotion == 1) {
      this.discount = 1
    } else {
      this.discount = null
    }
    if (this.goods.tag.length) {
      this.goods.tag = this.goods.tag.split('；')
      this.label = true
    } else {
      this.goods.tag = []
    }

    this.goods.coverUrl = this.isJson(this.goods.coverUrl)
      ? JSON.parse(this.goods.coverUrl)
      : [this.goods.coverUrl]
  },

  methods: {
    addCart() {
      this.$emit('getGoodsId', this.goods.id)
    },
    handleMouseOver() {
      this.autoplay = true
      console.log(' this.$refs.carouselRef', this.$refs.carouselRef)
    },
    handleMouseLever() {
      this.autoplay = false
    },

    isJson(str) {
      try {
        if (typeof JSON.parse(str) == 'object') {
          return true
        }
      } catch (e) {}
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  position: relative;
  display: inline-block;
  width: 285px;
  cursor: pointer;
  .item-img {
    overflow: hidden;
    width: 100%;
    height: 285px;
    border-radius: 8px;
    img {
      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
  .item-text {
    .item-name {
      overflow: hidden;
      padding: 8px 0;
      color: #000;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      .goods-sn {
        display: inline-block;
        padding: 0 5px;
        margin-right: 10px;
        color: #fff;
        background: #fcbbbb;
        border-radius: 6px;
      }
    }

    .buyLimit_wrapper {
      height: 36px;
      label {
        color: #979797;
        font-family: var(--font-family);
        font-size: var(--fs-16);
        font-weight: normal;
        // line-height: 36px;
      }
    }
    .vip {
      margin: 5px 0;
      img {
        width: 35px;
        margin-right: 10px;
      }
      span {
        font-size: 16px;
      }
    }
    .item-price {
      margin-top: 5px;
      color: #d43a57;
      font-size: 20px;
    }
    .item-icon {
      width: 24px;
      img {
        width: 100%;
      }
    }
    .product-icon_wrapper {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      .icon-collect {
        margin-right: 8px;
      }
    }
  }
}
</style>
